// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '8225c64c1b05c955d42c68344cdcba4c';
const css =`.form{align-items:baseline;display:flex;flex-wrap:wrap;margin-bottom:1rem;margin-top:1rem;max-width:48rem;padding:0}.form>*+*{margin-left:1rem}.formGroup{align-items:flex-start;display:flex;flex-grow:1;width:100%}.formGroup+.formGroup{margin-left:0;margin-top:1rem}.label{align-items:center;display:inline-flex;margin-right:1.5rem;min-height:2rem;text-align:left}.message{font-size:.875rem;line-height:1.4;margin-left:1rem}.form:not(.formStacked) .formGroup input[class*=_uikit-input],.form:not(.formStacked) .formGroup input[class*=_uikit-textarea]{width:100%}.form:not(.formStacked) .formGroup:not(.formGroupActions) .label{flex-basis:10rem;flex-grow:0;flex-shrink:0;width:10rem}:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) button+button,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) button+input,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) button+select,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) input+button,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) input+input,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) input+select,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) select+button,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) select+input,:is(.form:not(.formStacked) .formGroup:not(.formGroupActions)) select+select{margin-left:1rem}.formGroup.formGroupActions{flex-direction:row;gap:1rem;justify-content:flex-end}.form.formStacked .formGroup{align-items:flex-start;display:flex;flex-direction:column}.form.formStacked .formGroup.formGroupActions{gap:.25rem}.form.formStacked>*+*{margin-left:0}.form.formStacked>*+.label{margin-top:.5rem}.form.formStacked .message{margin-top:.25rem}`;
const styles = {
    ['form']: 'form',
    ['formGroup']: 'formGroup',
    ['label']: 'label',
    ['message']: 'message',
    ['formStacked']: 'formStacked',
    ['formGroupActions']: 'formGroupActions',
    inject() { injectStyles(key, css, options); }
};
styles.inject();
export { styles, css, key };
export default styles;
